// src/About.js
import React from 'react';
import 'animate.css';
import './Styles/About.css'

function About() {
    return (
        <div style={{
            paddingBottom: "50px"
        }} className="About section-container" id="about">
            <p className='p-self'>
                Welcome to my portfolio site! I'm a senior studying computer science at the University of Wisconsin
                - Madison,<a className='p-link' href="https://cdis.wisc.edu/" target="_blank" rel="noreferrer"> School of Computer, Data & Information Science</a>, who is excited to share my array of experiences and projects. They reflect both my academic growth and boundless enthusiasm for technology.
                Having just completed my software engineering internship at Microsoft, I'm now focused on further enhancing my full-stack skills. Enjoy your visit, and let's stay connected!
            </p>
        </div>
    );
}

export default About;
